import {ChangeDetectionStrategy, Component, ContentChild, Directive, Input, TemplateRef} from '@angular/core'
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import {faChevronSquareDown} from '@fortawesome/pro-solid-svg-icons/faChevronSquareDown'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'

@Directive({
  selector: '[cftNavListItemExpandableTitle]',
})
export class NavListItemExpandableTitleDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Component({
  selector: 'cft-nav-list-item-expandable, li[cft-nav-list-item-expandable]',
  templateUrl: './nav-list-item-expandable.component.html',
  styleUrls: ['./nav-list-item-expandable.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavListItemExpandableComponent {
  @Input()
  title!: string

  @Input()
  testIdList?: string

  @Input()
  isExpanded = true

  @Input()
  indentation = 0

  @Input()
  icon?: IconDefinition

  @ContentChild(NavListItemExpandableTitleDirective) titleContainer!: NavListItemExpandableTitleDirective

  readonly chevronIcon = faChevronSquareDown
  readonly caretIcon = faCaretDown

  getIcon() {
    return this.indentation < 1 ? this.chevronIcon : this.caretIcon
  }

  toggle() {
    this.isExpanded = !this.isExpanded
  }
}
