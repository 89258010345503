import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {NavListComponent} from './nav-list.component'
import {NavListItemComponent} from './nav-list-item/nav-list-item.component'
import {
  NavListItemExpandableComponent,
  NavListItemExpandableTitleDirective,
} from './nav-list-item-expandable/nav-list-item-expandable.component'

@NgModule({
  declarations: [
    NavListComponent,
    NavListItemComponent,
    NavListItemExpandableComponent,
    NavListItemExpandableTitleDirective,
  ],
  imports: [CommonModule, FontAwesomeModule],
  exports: [
    NavListComponent,
    NavListItemComponent,
    NavListItemExpandableComponent,
    NavListItemExpandableTitleDirective,
  ],
})
export class NavListModule {}
