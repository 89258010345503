import {ChangeDetectionStrategy, Component, HostBinding} from '@angular/core'

@Component({
  selector: 'cft-nav-list, ul[cft-nav-list], ol[cft-nav-list]',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavListComponent {
  @HostBinding('class.space-y-1')
  childSpace = true
}
