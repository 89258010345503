<button cft-nav-list-item [indentation]="indentation" class="items-center justify-between" (click)="toggle()">
  @if (title) {
    <span>
      @if (icon) {
        <fa-icon class="pr-xs" [icon]="icon"></fa-icon>
      }
      {{ title }}
    </span>
  }
  @if (!title) {
    <span>
      <ng-container [ngTemplateOutlet]="titleContainer.templateRef"></ng-container>
    </span>
  }
  <fa-icon
    [icon]="getIcon()"
    [class.mr-2xs]="indentation >= 1"
    [class.fa-flip-vertical]="isExpanded"
    class="toggle-icon"
  ></fa-icon>
</button>
<nav class="expandable" [class.expanded]="isExpanded" [attr.data-testid]="testIdList">
  <ng-content></ng-content>
</nav>
