import {ChangeDetectionStrategy, Component, HostBinding, OnInit, Input} from '@angular/core'

@Component({
  selector: 'cft-nav-list-item, li[cft-nav-list-item], a[cft-nav-list-item], button[cft-nav-list-item]',
  templateUrl: './nav-list-item.component.html',
  styleUrls: ['./nav-list-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavListItemComponent implements OnInit {
  @Input()
  indentation = 0

  @HostBinding('class.indentation-0')
  indentationIs0 = false

  @HostBinding('class.indentation-1')
  indentationIs1 = false

  @HostBinding('class.indentation-2')
  indentationIs2 = false

  ngOnInit() {
    this.indentationIs0 = this.indentation === 0
    this.indentationIs1 = this.indentation === 1
    this.indentationIs2 = this.indentation === 2
  }
}
